// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  sandboxstage:false,
  env: 'local',
  baseUrl: 'https://dev.portal.api.irisirp.com/portal',
  apiUrl: 'https://api.sandbox.portal.irisirp.com/portal',
  prodUrl:'https://api.prod.portal.irisirp.com/portal',
  excelUrl : 'assets/IRIS_IRP_v0.10_Excel Utility-DEV.xlsm',
  excelFileName: 'IRIS_IRP_v0.10_Excel Utility-DEV.xlsm',
  ewbUrl:'https://dev.portal.api.irisirp.com/ewbportal'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

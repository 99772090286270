import { Injectable } from '@angular/core';
import { SharedService } from 'src/app/shared/shared.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor(private http: HttpClient, private sharedService: SharedService) { }

  getGSTINStatus(gstin){

    return this.http
      .get(
        `${environment.baseUrl}/taxpayer/search?gstin=${gstin}`
      )

  }
  getUpdatedStatus(gstin){
    return this.http
    .post(
      `${environment.baseUrl}/gstin/update/from/common/portal?gstin=${gstin}`,{}
    )
  }
  getInvoiceStatus(gstin){

    return this.http
      .get(
        `${environment.baseUrl}/einvoice/status?gstin=${gstin}`
      )

  }
  downloadExcelUtilityFile(){
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', environment.excelUrl);
    link.setAttribute('download', environment.excelFileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
}
